import C from '../constants'

export const getFeaturesData = (t) => [
  {
    icon: 'calendar',
    heading: t('FeaturePage:featureScheduling:heading'),
    detail: t('FeaturePage:featureScheduling:detail'),
    link: C.internalLinks.scheduling,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },
  {
    icon: 'layers',
    heading: t('FeaturePage:featureService:heading'),
    detail: t('FeaturePage:featureService:detail'),
    link: C.internalLinks.services,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },
  {
    icon: 'people',
    heading: t('FeaturePage:featureClients:heading'),
    detail: t('FeaturePage:featureClients:detail'),
    link: C.internalLinks.clients,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },

  {
    icon: 'documents',
    heading: t('FeaturePage:featureQuotes:heading'),
    detail: t('FeaturePage:featureQuotes:detail'),
    link: C.internalLinks.quotes,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },
  {
    icon: 'reader',
    heading: t('FeaturePage:featureInvoices:heading'),
    detail: t('FeaturePage:featureInvoices:detail'),
    link: C.internalLinks.invoices,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },
  {
    icon: 'statsChart',
    heading: t('FeaturePage:featureFinancial:heading'),
    detail: t('FeaturePage:featureFinancial:detail'),
    link: C.internalLinks.financial,
    linkText: t('Global:learnMore'),
    featuredOnHomePage: true,
  },

  {
    icon: 'chatbubble',
    heading: t('FeaturePage:featureText:heading'),
    detail: t('FeaturePage:featureText:detail'),
    link: null,
    linkText: null,
  },
  {
    icon: 'clipboard',
    heading: t('FeaturePage:featureInventory:heading'),
    detail: t('FeaturePage:featureInventory:detail'),
    link: null,
    linkText: null,
  },
  {
    icon: 'cart',
    heading: t('FeaturePage:featureExpenses:heading'),
    detail: t('FeaturePage:featureExpenses:detail'),
    link: null,
    linkText: null,
  },
]
