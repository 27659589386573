export const getSchedulingRowData = (t) => [
  {
    icon: 'schedule',
    heading: t('SchedulingPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'tablet',
    heading: t('SchedulingPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'time',
    heading: t('SchedulingPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'personAdd',
    heading: t('SchedulingPage:featureHeading4'),
    variant: 'detail',
  },
]
