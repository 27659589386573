export const getInvoicesRowData = (t) => [
  {
    icon: 'checkbox',
    heading: t('InvoicesPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'alarm',
    heading: t('InvoicesPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'chatbubble',
    heading: t('InvoicesPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'images',
    heading: t('InvoicesPage:featureHeading4'),
    variant: 'detail',
  },
]
