/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import RowContainer from '../RowContainer'
import FeatureBox from '../FeatureBox'
import { getFeaturesData } from '../../pageData/features_data'

const Features = ({ featuredOnly = false }) => {
  const { t } = useTranslation()
  const rowData = getFeaturesData(t)
  const filteredData = featuredOnly
    ? rowData.filter((item) => item.featuredOnHomePage === featuredOnly)
    : rowData
  return (
    <RowContainer>
      {filteredData.map((featureData, index) => (
        <FeatureBox {...featureData} key={index} />
      ))}
    </RowContainer>
  )
}

export default Features
