export const getQuotesRowData = (t) => [
  {
    icon: 'images',
    heading: t('QuotesPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'mail',
    heading: t('QuotesPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'calendar',
    heading: t('QuotesPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'analytics',
    heading: t('QuotesPage:featureHeading4'),
    variant: 'detail',
  },
]
