/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'

const BannerWrapper = ({ children }) => (
  <Box bg="lynxWhite50" sx={{ paddingX: [0, 0, 4, '50px'] }}>
    {children}
  </Box>
)

export default BannerWrapper
