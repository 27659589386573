export const getClientsRowData = (t) => [
  {
    icon: 'people',
    heading: t('ClientsPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'phone',
    heading: t('ClientsPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'pieChart',
    heading: t('ClientsPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'chatbubble',
    heading: t('ClientsPage:featureHeading4'),
    variant: 'detail',
  },
]
