/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { jsx } from 'theme-ui'

import RowContainer from '../RowContainer'
import FeatureBox, { ROW_LAYOUT_TYPE } from '../FeatureBox'

const SummaryRow = ({ summaryData = [] }) => (
  <RowContainer>
    {summaryData.map((summaryItem) => (
      <FeatureBox
        {...summaryItem}
        key={summaryItem.heading}
        rowWidth={ROW_LAYOUT_TYPE.FOUR_IN_A_ROW}
      />
    ))}
  </RowContainer>
)

export default SummaryRow
