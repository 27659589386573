export const getFinancialOverviewRowData = (t) => [
  {
    icon: 'cash',
    heading: t('FinancialOverviewPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'swapHorizontal',
    heading: t('FinancialOverviewPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'sync',
    heading: t('FinancialOverviewPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'exit',
    heading: t('FinancialOverviewPage:featureHeading4'),
    variant: 'detail',
  },
]
