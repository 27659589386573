/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import BannerWrapper from '../components/BannerWrapper'
import Features from '../components/Features'
import SummaryRow from '../components/SummaryRow'
import Icon from '../components/Icon'

import { getFeaturesData } from '../pageData/features_data'

import { getSchedulingRowData } from '../pageData/scheduling_data'
import { getServiceRowData } from '../pageData/service_data'
import { getClientsRowData } from '../pageData/clients_data'
import { getQuotesRowData } from '../pageData/quotes_data'
import { getInvoicesRowData } from '../pageData/invoices_data'
import { getFinancialOverviewRowData } from '../pageData/financial_overview_data'

const size = 40

const TestIcons = () => {
  const { t } = useTranslation()
  const featureRowData = getFeaturesData(t)

  const schedulingRowData = getSchedulingRowData(t)
  const serviceRowData = getServiceRowData(t)
  const clientsRowData = getClientsRowData(t)

  const quotesRowData = getQuotesRowData(t)
  const invoicesRowData = getInvoicesRowData(t)
  const financialOverviewRowData = getFinancialOverviewRowData(t)

  return (
    <BannerWrapper>
      <Features featureRowData={featureRowData} />
      <SummaryRow summaryData={schedulingRowData} />
      <SummaryRow summaryData={serviceRowData} />
      <SummaryRow summaryData={clientsRowData} />

      <SummaryRow summaryData={quotesRowData} />
      <SummaryRow summaryData={invoicesRowData} />
      <SummaryRow summaryData={financialOverviewRowData} />

      <Flex sx={{ paddingBottom: 10 }}>
        <Icon size={size} color="green" iconName="arrowLeft" />
        <Icon size={size} color="green" iconName="arrowRight" />
        <Icon size={size} color="green" iconName="add" />
        <Icon size={size} color="green" iconName="alarm" />
        <Icon size={size} color="green" iconName="analytics" />
        <Icon size={size} color="green" iconName="archive" />
        <Icon size={size} color="green" iconName="barChart" />
        <Icon size={size} color="green" iconName="barCode" />
        <Icon size={size} color="green" iconName="business" />
        <Icon size={size} color="green" iconName="calendar" />
        <Icon size={size} color="green" iconName="card" />
        <Icon size={size} color="green" iconName="cart" />
        <Icon size={size} color="green" iconName="cash" />
        <Icon size={size} color="green" iconName="chatbubble" />
        <Icon size={size} color="green" iconName="clipboard" />
        <Icon size={size} color="green" iconName="documents" />
        <Icon size={size} color="green" iconName="exit" />
        <Icon size={size} color="green" iconName="images" />
        <Icon size={size} color="green" iconName="layers" />
        <Icon size={size} color="green" iconName="mail" />
        <Icon size={size} color="green" iconName="people" />
        <Icon size={size} color="green" iconName="personAdd" />
        <Icon size={size} color="green" iconName="phone" />
        <Icon size={size} color="green" iconName="pieChart" />
        <Icon size={size} color="green" iconName="reader" />
        <Icon size={size} color="green" iconName="schedule" />
        <Icon size={size} color="green" iconName="statsChart" />
        <Icon size={size} color="green" iconName="swapHorizontal" />
        <Icon size={size} color="green" iconName="sync" />
        <Icon size={size} color="green" iconName="time" />
        <Icon size={size} color="green" iconName="train" />
      </Flex>
    </BannerWrapper>
  )
}

export default TestIcons
