export const getServiceRowData = (t) => [
  {
    icon: 'layers',
    heading: t('ServicesPage:featureHeading1'),
    variant: 'detail',
  },
  {
    icon: 'cash',
    heading: t('ServicesPage:featureHeading2'),
    variant: 'detail',
  },
  {
    icon: 'schedule',
    heading: t('ServicesPage:featureHeading3'),
    variant: 'detail',
  },
  {
    icon: 'reader',
    heading: t('ServicesPage:featureHeading4'),
    variant: 'detail',
  },
]
